<script>
export default {
  name: 'Marketing',
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  directives: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
    document.body.classList.add('mkt-module-body')
    this.erplayout.erpheader.menu.setActiveMenu('marketing')
  },
  destroyed() {
    document.body.classList.remove('mkt-module-body')
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {},
  meta: {
    title: 'Marketing',
    name: 'Marketing'
  }
}
</script>

<template>
  <div class="mkt-module">
    <router-view />
  </div>
</template>

<style src="./assets/default.styl" lang="stylus"></style>

